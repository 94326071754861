<template>
  <div class="auth__area">
    <Loader full :loading="isLoading" />

    <h1>
      {{ $t('Welcome back, {userName}.', { userName: user.customername }) }}
    </h1>
    <h4>{{ $t('Please enter your password in the box below.') }}</h4>

    <form @submit.prevent="onFormSubmit">
      <transition name="fade">
        <InfoBox v-if="isForgotPasswordSubmited" type="info" class="pt-0 pb-2">
          <span>
            {{
              $t(
                'You have received instructions via email for your new password.'
              )
            }}
          </span>
        </InfoBox>
      </transition>

      <div class="row mb-4">
        <InputBox
          id="password"
          v-model="form.password"
          :focus="true"
          :errors="errors"
          :label="$t('Password')"
          type="password"
        >
          <a
            slot="input-append"
            class="d-flex align-items-center px-3 text-medium"
            @click="handleForgotPassword"
          >
            {{ $t('Forgot your password?') }}
          </a>
        </InputBox>
      </div>

      <div
        class="
          row
          no-gutters
          flex-row-reverse
          d-sm-flex
          justify-content-between
          align-items-center
          mx-5
          mb-3
          mx-lg-0
        "
      >
        <div class="d-flex align-items-center col col-lg-6 mb-3 mb-lg-0">
          <a
            class="mr-24px"
            @click="() => $emit('changeStep', { name: 'login' })"
          >
            {{ $t('Back') }}
          </a>
          <button class="btn btn-primary col" type="submit">
            {{ $t('Sign in') }}
          </button>
        </div>

        <div class="col-sm-12 justify-content-center col-lg-6">
          <SupportBlock minified />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from 'common/components/Loader';
import InfoBox from 'common/components/InfoBox.vue';
import SupportBlock from '@/components/Partials/SupportBlock';

import InputBox from '@/components/InputBox';

export default {
  name: 'LoginPassword',
  components: { InputBox, Loader, InfoBox, SupportBlock },
  data() {
    return {
      isForgotPasswordSubmited: false,
      form: {
        password: '',
      },
      errors: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.core['user'];
    },
    isLoading() {
      return (
        this.$store.state.loading['core/resendCode'] ||
        this.$store.state.loading['core/login'] ||
        this.$store.state.loading['core/forgotPassword']
      );
    },
  },
  mounted() {
    if (!this.user.email) {
      this.$emit('changeStep', { name: 'login' });
    }
  },
  methods: {
    async onFormSubmit() {
      this.errors = {};
      this.isForgotPasswordSubmited = false;

      const response = await this.$store.dispatch('core/login', {
        userid: this.user.email,
        password: this.form.password,
      });

      if (!response.ok) {
        this.errors =
          typeof response.error === 'object'
            ? response.error
            : { password: { message: response.error } };
      }
    },
    async handleForgotPassword() {
      this.isForgotPasswordSubmited = false;

      const response = await this.$store.dispatch(
        'core/forgotPassword',
        this.user.email
      );

      if (response.ok) {
        this.isForgotPasswordSubmited = true;
      }
    },
  },
};
</script>

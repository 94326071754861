<template>
  <div class="auth__area">
    <Loader full :loading="isLoading" />

    <h1>{{ $t('Welcome back, {userName}.', { userName: user.customername }) }}</h1>
    <h4>
      {{ $t('Please enter the code you received via email in the box below.') }}
    </h4>

    <form novalidate="true" @submit.prevent="onSubmit">
      
      <transition name="fade">
        <InfoBox v-if="isCodeResent" type="info" class="pt-0 pb-2">
          <span>
            {{
              $t(
                'We just sent your verification code, please check your inbox and spam folder.'
              )
            }}
          </span>
        </InfoBox>
      </transition>
      
      <div class="row mb-4">
        <InputBox
          id="code"
          v-model="form.code"
          :errors="errors"
          :focus="true"
          :label="$t('Verification Code')"
          type="text"
        >
          <a
            slot="input-append"
            class="d-flex align-items-center px-3"
            :class="{
              disabled: timeout > 0
            }"
            @click="resendCode"
          >
            {{ timeout > 0 ? $tc('Resend ({count}s)', timeout) : $t('Resend') }}
          </a>
        </InputBox>
      </div>

      <div
        class="
          row
          no-gutters
          flex-row-reverse
          d-sm-flex
          justify-content-between
          align-items-center
          mx-5
          mb-3
          mx-lg-0
        "
      >
        <div class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0">
          <a class="btn btn-link mr-4" @click="() => $emit('changeStep', { name: 'login' })">
            {{ $t('Back') }}
          </a>
          <button class="btn btn-primary col" type="submit">
            {{ $t('Sign in') }}
          </button>
        </div>

        <div class="col-sm-12 justify-content-center col-lg-6">
          <SupportBlock minified />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputBox from '@/components/InputBox';
import Loader from 'common/components/Loader';
import SupportBlock from '@/components/Partials/SupportBlock';
import InfoBox from 'common/components/InfoBox.vue';

export default {
  name: 'LoginCode',
  components: { InputBox, Loader, SupportBlock, InfoBox },
  props: {
    initialTimeout: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      form: {
        code: ''
      },
      timeout: 0,
      interval: null,
      isCodeResent: false,
      attributes: {},
      errors: {}
    };
  },
  computed: {
    user() {
      return this.$store.state.core['user'];
    },
    isLoading(){
      return this.$store.state.loading['core/resendCode'] || this.$store.state.loading['core/login'];
    }
  },
  mounted() {
    
    if(this.$route) {
      
      const { attributes } = this.$route.params;
      
      // check params in the url
      if(attributes) {
        this.onAutoSubmit(attributes);
      }

    }

    if(!this.user.email && !this.$route.params?.attributes) {
      this.$emit('changeStep', { name: 'login' });
    }

    // handle timeout countdown
    this.timeout = this.initialTimeout;
    this.interval = setInterval(() => {
      this.timeout--;
    }, 1000);
    
  },
  methods: {
    async onSubmit() {

      this.errors = {};

      if(!this.attributes.code && !this.form.code) {
        this.errors = { code: { message: this.$t('If you haven\'t got the code yet, check your spam folder or try clicking \'Resend\' to send a new code') } };
        return;
      }

      const response = await this.$store.dispatch('core/login', {
        userid: (this.attributes.email) ? this.attributes.email : this.user.email,
        code: (this.form.code) ? this.form.code : this.attributes.code
      });

      if(!response.ok) {
        this.errors = typeof response.error === 'object' ? response.error : { code: { message: response.error } };
      }

    },
    async resendCode() {
      
      this.isCodeResent = false;
      
      const response = await this.$store.dispatch(
        'core/resendCode',
        this.attributes.email ? this.attributes.email : this.user.email
      );
      
      this.isCodeResent = true;
      
      this.timeout = response;
      this.form.code = '';
      this.errors = {};
    },
    async onAutoSubmit(attributes) {
      try {
        const [ email, code ] = atob(attributes).split('/');
        this.attributes = {
          email,
          code
        }
        this.onSubmit();
      } catch (e) {
        this.errors = { code: { message: 'Failed to decode the validation token.' } };
      }

    },
  },
};
</script>

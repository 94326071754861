<template>
  <div class="auth__area">
    <Loader full :loading="isLoading" />

    <h1>{{ $t('FileCloud Customer Portal') }}</h1>
    <h4>{{ $t('Buy FileCloud and Manage your Licenses') }}</h4>

    <form novalidate="true" @submit="onSubmit">
      <div class="row mb-4">
        <InputBox
          id="userid"
          v-model="form.email"
          :focus="true"
          :label="$t('Email Address')"
          type="email"
          :errors="errors"
          auto-select
        />
      </div>
      <div
        class="
          row
          no-gutters
          flex-row-reverse
          d-sm-flex
          justify-content-between
          align-items-center
          mx-5
          mb-3
          mx-lg-0
        "
      >
        <div class="col-sm-12 col-lg-4">
          <button class="btn btn-primary mb-3 mb-lg-0 col-12" type="submit">
            {{ $t('Next') }}
          </button>
        </div>

        <div class="col-sm-12 col-lg-8">
          <SupportBlock minified />
        </div>
      </div>

      <!-- <div class="divider">
        {{ $t('or') }}
      </div>

      <div
        class="
          row
          no-gutters
          flex-row-reverse
          d-sm-flex
          justify-content-center
          align-items-center
          mx-5
          mb-3
          mx-lg-0
        "
      >
        <div class="col-sm-12 col-lg-5">
          <router-link
            class="btn btn-outline-primary mb-3 mb-lg-0 col-12"
            type="submit"
            to="/trial"
          >
            {{ $t('Register for a free trial') }}
          </router-link>
        </div>
      </div> -->
    </form>
  </div>
</template>

<script>
import InputBox from '@/components/InputBox';
import Loader from 'common/components/Loader';
import SupportBlock from '@/components/Partials/SupportBlock';

export default {
  name: 'Login',
  components: { InputBox, Loader, SupportBlock },
  data() {
    return {
      form: {
        email: '',
      },
      errors: {},
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['core/verifyGuest'];
    },
    user() {
      return this.$store.state.core.user;
    },
  },
  mounted() {
    if (this.user.email) {
      this.form.email = this.user.email;
    }
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();

      const response = await this.$store.dispatch(
        'core/verifyGuest',
        this.form.email
      );

      if (response.error) {
        this.errors = response.error;
        return;
      } else if (!response.data?.exists) {
        this.errors = {
          userid: {
            message: this.$t(
              'No account for that email address has been found.'
            ),
          },
        };
        this.$store.commit('core/set', { user: { email: this.form.email } });
        return;
      }

      this.errors = {};

      if (response.data?.haspassword) {
        this.$emit('changeStep', { name: 'password' });
      } else {
        this.$emit('changeStep', {
          name: 'confirm',
          timeout: response.data?.timeout,
        });
      }
    },
  },
};
</script>

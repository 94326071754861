<template>
  <div class='auth__wrapper row no-gutters'>
    <div class='col-lg-6 col-xs-12 vh-100'>
      <div class='login__component d-flex'>

        <div class='auth__header text-center'>
          <Logo />
        </div>

        <slot :name='step'></slot>

        <div class='login__footer'>
          <small>FileCloud &copy; {{ currentYear }}</small>
        </div>

      </div>
    </div>

    <div
      class='col-lg-6 d-none d-lg-block bg-card vh-100'
    >

      <slot name="banner"></slot>

      <div class="fullbanner-clients">
        <h4>{{$t('Trusted by')}}</h4>
        <div class="clients-list">
          <img src="../../assets/clients.png" />
        </div>
      </div>

    </div>

    <vue-headful :title="$t('FileCloud Customer Portal')" />

  </div>
</template>

<script>

import Logo from '../../assets/logo.svg';

export default {
  name: 'LoginPageWrapper',
  components: { Logo },
  props: { step: {
    type: String,
    default: 'login'
  }},
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .35s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/*.slide-leave-active,
.slide-enter-active {
  transition: 0.4s;
}
.slide-enter {
  transform: translate(100%, 0);
  opacity: 0;
}
.slide-leave-to {
  transform: translate(-100%, 0);
  opacity: 0;
}*/
</style>

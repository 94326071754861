<template>
  <LoginPageWrapper :step='step'>
    <template #login>
      <Login
        @changeStep="onChangeStep"
      />
    </template>

    <template #password>
      <LoginPassword
        @changeStep="onChangeStep"
      />
    </template>

    <template #confirm>
      <LoginCode
        :initial-timeout="timeout"
        @changeStep="onChangeStep"
      />
    </template>

    <template #banner>
      <img
        class='login__main-bg main-bg'
        src='../assets/aurora-bg.jpg'
      />
    </template>
  </LoginPageWrapper>
</template>

<script>
import LoginPageWrapper from '@/components/Auth/LoginPageWrapper';
import Login from '@/components/Auth/Login';
import LoginPassword from '@/components/Auth/LoginPassword';
import LoginCode from '@/components/Auth/LoginCode';

export default {
  name: 'Auth',
  components: {
    LoginCode,
    LoginPassword,
    Login,
    LoginPageWrapper
  },
  data() {
    return {
      /**
       * @step 'login' | 'password | 'code'
       */
      // step: 'code',
      timeout: 0
    };
  },
  computed: {
    step() {
      // if it's a subpage
      if(this.$route.name === 'loginSub' && ['password', 'confirm'].indexOf(this.$route.params?.section) > -1) {
        return this.$route.params?.section;
      }
      return 'login';
    }
  },
  methods: {
    onChangeProp(key, value) {
      this[key] = value;
    },
    onChangeStep({ name, timeout }) {
      if(name === 'login') {
        this.$router.push('/login')
      } else {
        this.$router.push(`/login/${name}`)
      }
      if(timeout) {
        this.timeout = timeout;
      }
    },
  }
};
</script>
